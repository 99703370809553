@use '../../scss/variables' as v;
@import '../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
  &__marginTop {
    margin-top: 20px;
  }
  :global {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #ed6c01 !important;
      border-color: #ed6c01 !important;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: #ed6c01 !important;
      border-color: #ed6c01 !important;
    }
    td.ant-table-cell:nth-child(1) {
      cursor: default;
    }
    td.ant-table-cell {
      cursor: text;
    }
    th.ant-table-cell {
      cursor: default;
    }
    td.ant-table-cell {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
    td {
      vertical-align: middle !important;
    }
    td.ant-table-cell {
      .editable-cell-value-wrap {
        border: 0.5px solid transparent;
        padding: 5px 10px;
        text-align: start;
      }
    }
    td.ant-table-cell {
      &:hover {
        .editable-cell-value-wrap {
          border: 0.5px solid gray;
        }
      }
    }
  }
}

.title {
  margin-left: 76px;
}

.content {
  height: 100%;
  flex: 1;
  overflow: auto;
  @include mobile {
    margin: 10px;
  }
}

.contentWrap {
  height: 100%;
  border: 1px solid v.$border-cl;
}

.contentTitle {
  display: flex;
  align-items: center;
  margin-top: 35px;
  padding-left: 10px;
  border: 1px solid v.$border-cl;
  background: rgba(0, 0, 0, 0.03);
  height: 50px;
  font-size: larger;
}

.form {
  padding: 10px;
}
.pForm {
  padding-left: 10px;
}

.noMargin {
  margin: 0;
}
