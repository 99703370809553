.ant-checkbox-wrapper {
  $checkbox-color: #ed6c01;
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $checkbox-color;
        border-color: $checkbox-color;
      }
      &::after {
        border: 1px solid $checkbox-color;
        border-radius: 4px;
      }
    }
  }
  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $checkbox-color;
  }
}

.ant-form {
  .ant-form-item {
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }
    .ant-form-item-explain {
      margin-bottom: 24px;
      text-align: left;
      font-size: 12px;
    }
  }
}

.ant-table-cell-ellipsis {
  white-space: unset;
}
.ant-table-cell {
  border: 1px solid $black-cl !important;
  border-left-width: 0px !important;
  border-bottom-width: 0px;
  &:first-child {
    border-left-width: 1px !important;
  }
}
.ant-table-row {
  border-bottom-width: 0px !important;
}
.ant-table-thead {
  .ant-table-cell {
    background-color: $blue-cl;
    color: $white-cl;
  }
  th.ant-table-column-has-sorters:hover,
  th.ant-table-column-sort {
    background: $blue-cl-hover;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: $primary_t1;
  }
}
.ant-table table {
  border-collapse: collapse;
}
