@use '../../scss/variables' as v;

.rowWrap {
  height: 100%;
  .cardWrap {
    width: 438px;
    .formWarp {
      padding: 0 16px;
      font-size: 16px;
      .forgotPw {
        margin-left: 16px;
      }
      .text {
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
      .email {
        margin: 24px 0;
      }
    }
  }
}
