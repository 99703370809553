@use '../../scss/variables' as v;
@import '../../scss/mixins';

.bodySection {
  margin: 30px 0 10px;
  font-size: v.$text-bg;
  span {
    &:first-child {
      margin-right: 20px;
    }
  }
}
.selectUserResult {
  width: 210px;
}
.tableResult {
  margin-top: 20px;
  th {
    background-color: v.$blue-cl;
    color: v.$white-cl;
    font-weight: v.$font-weight-normal;
    font-size: v.$text-sm-size;
    line-height: 34px;
  }
  td {
    font-size: v.$text-bg-2;
  }
  th,
  td {
    width: 225px;
    height: 34px;
    border: 1px solid v.$black-cl;
    text-align: center;
  }
}
.tableResultAnswer {
  margin-top: 20px;
  font-size: 16px;
  th {
    background-color: v.$blue-cl;
    color: v.$white-cl;
    font-weight: v.$font-weight-normal;
    font-size: v.$text-sm-size;
    line-height: 34px;
  }
  th,
  td {
    width: 225px;
    border: 1px solid v.$black-cl;
    text-align: center;
  }
  .tableRow {
    height: 164px;
    &.wrongAnswer {
      background-color: v.$pink-cl;
    }
    td {
      padding: 8px 12px;
      line-height: 16px;
    }
    .cellJustify {
      text-align: center;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
    .cellStart {
      text-align: left;
      line-height: 150%;
    }
    :nth-child(2) {
      width: 5%;
    }
    :nth-child(3) {
      width: 15%;
    }
    :nth-child(4) {
      width: 25%;
    }
    :nth-child(5) {
      width: 15%;
    }
    :nth-child(6) {
      width: 15%;
    }
    :nth-child(7) {
      width: 20%;
    }
  }
}
.bodySectionComment {
  margin: 26px 0 15px;
  font-size: v.$text-bg;
}
.progressControlWrapper {
  margin-top: 20px;
  p {
    margin-right: 16px;
  }
}
.progressPlot {
  width: 70%;
  canvas {
    max-width: 100% !important;
    height: 100px !important;
  }
}
.linePlotWrapper {
  margin-top: 20px;
  .linePlot {
    width: 100%;
    canvas {
      max-width: 100% !important;
      height: 306px !important;
    }
  }
}
.descriptionPlotWrapper {
  margin-top: 20px;
  span {
    margin-left: 16px;
  }
  .redColor {
    stroke: #ff0000;
  }
  .blueColor {
    stroke: #132bff;
  }
}
