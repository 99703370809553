@use '../../scss/variables' as v;
@import '../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
  &__marginTop {
    margin-top: 20px;
  }

  .title {
    margin-left: 76px;
  }

  .content {
    height: 100%;
    flex: 1;
    overflow: auto;
    @include mobile {
      margin: 10px;
    }
  }

  .contentWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    .questionCard {
      width: 100%;
      height: 210px;
      margin-top: 12px;
      font-size: 16px;
      span {
        font-size: 16px;
      }
    }
    .questionCardTitle {
      text-align: center;
      font-size: 22px;
    }
    border: 1px solid v.$border-cl;
  }

  .contentTitle {
    display: flex;
    align-items: center;
    margin-top: 35px;
    padding: 8px 16px 8px 16px;
    border: 1px solid v.$border-cl;
    background: rgba(0, 0, 0, 0.03);
    font-size: larger;
  }

  .form {
    padding: 10px;
    .title {
      margin: 10px 0;
    }
    .select {
      width: 50%;
      margin: 10px 0;
    }
  }

  :global {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #ed6c01 !important;
      border-color: #ed6c01 !important;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: #ed6c01 !important;
      border-color: #ed6c01 !important;
    }
    td.ant-table-cell[title~='適正範囲'] {
      cursor: pointer;
    }
    td.ant-table-cell[title~='適正範囲'] {
      .editable-cell-value-wrap {
        border: 0.5px solid transparent;
        padding: 10px;
      }
    }
    td.ant-table-cell[title~='適正範囲'] {
      &:hover {
        .editable-cell-value-wrap {
          border: 0.5px solid gray;
        }
      }
    }
    th.ant-table-cell,
    td.ant-table-cell {
      cursor: default;
    }
    td.ant-table-cell {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  }
}
