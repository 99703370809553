@use '../../scss/variables' as v;
@import '../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 70px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
  .titleTime {
    margin-left: 36px;
  }
  .title {
    margin-top: 20px;
    font-size: 22px;
  }
  .titleButton {
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .titleSelect {
    margin-top: 20px;
    width: 250px;
  }
  .content {
    height: 100%;
    flex: 1;
    overflow: auto;
    padding-top: 35px;
    @include mobile {
      margin: 10px;
    }
  }

  .contentWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
