@use '../../scss/variables' as v;
@import '../../scss/mixins';

.colTable {
  height: 50px;
  border-right: 1px solid v.$border-cl;
  display: flex;
  align-items: center;
}
.titleCol {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
}
.formItem {
  margin: 0;
  text-align: center;
}
