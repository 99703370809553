* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
html,
body,
#root {
  // min-width: 1080px;
  // max-width: $max-width;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  margin: 0 !important;
  background: $white-cl;
  font-family: 'Noto Sans JP', sans-serif;
  // overflow: hidden;
  position: relative;
  a {
    text-decoration: none;
  }
}
