@use '../../scss/variables' as v;
@import '../../scss/mixins';

.assignmentsRfpWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
}
.titleBig {
  font-size: 22px;
  margin: 20px 0;
}
.title {
  margin-left: 76px;
  @include mobile {
    margin-left: 16px;
  }
}
.subTitle {
  margin-top: 10px;
}
.titleContent {
  margin: 20px 0 30px;
}
.contentRight {
  justify-content: end;
}

.contentWrap {
  height: 100%;
  flex: 1;
  border: 1px solid v.$black-cl;
  margin: 30px 70px;
  padding: 16px;
  &__bottom {
    display: flex;
    margin-top: 20px;
    @include mobile {
      flex-direction: column-reverse;
    }
    .left {
      width: 80%;
      @include mobile {
        width: 100%;
      }
    }
    .right {
      width: 20%;
      @include mobile {
        width: 100%;
      }
    }
  }
  @include mobile {
    margin: 10px;
  }
}

.tableInfo {
  margin-top: 20px;
}

.divider {
  background-color: v.$gray-cl;
  height: 2px;
  margin: 5px 0 10px !important;
}
