@use '../../scss/variables' as v;

.topWrap {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  h2 {
    position: absolute;
    top: 25px;
    left: 35px;
    font-size: 22px;
    font-weight: 400;
  }
}
