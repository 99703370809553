@use '../../scss/variables' as v;
@import '../../scss/mixins';

.stepWrap {
  display: flex;
  border: 0 solid #ccc !important;
  padding: 0;
  border-radius: 5px;
  list-style: none;
  overflow: hidden;
  margin-top: 4px;
  width: 100%;
  overflow-x: auto;
  flex: 1;
}

.step {
  background-color: v.$gray-cl;
  display: flex;
  height: 50px;
  width: 110px;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  @include tablet-custom{
    width: 60px;
  }
  &.current {
    background-color: v.$blue-cl-light;
    &:after {
      z-index: 2;
      margin-left: 0px;
      border-left: 30px solid v.$blue-cl-light !important;
    }
  }
  &.active {
    background-color: v.$blue-cl;
    &:after {
      z-index: 2;
      margin-left: 0px;
      border-left: 30px solid v.$blue-cl !important;
    }
  }
  &.missing {
    background-color: v.$pink-cl;
    &:after {
      z-index: 2;
      margin-left: 0px;
      border-left: 30px solid v.$pink-cl !important;
    }
  }
  &:first-child {
    p {
      margin-left: 0 !important;
    }
  }
  &:before,
  &:after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }
  &:after {
    z-index: 2;
    margin-left: 0px;
    border-left: 30px solid v.$gray-cl !important;
  }
  &:hover {
    background-color: v.$blue-cl;
    p {
      color: v.$white-cl !important;
    }
    &:after {
      border-left: 30px solid v.$blue-cl !important;
    }
  }
  p {
    width: 130px;
    color: v.$text-gray-cl;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0;
    outline-style: none;
    font-size: var(--form-font-size);
    font-weight: var(--form-font-weigh);
    margin-left: 40px;
    margin-bottom: 8px;
  }
}

.linkActive {
  background-color: v.$blue-cl;
  p {
    color: v.$white-cl !important;
  }
  &:after {
    border-left: 30px solid v.$blue-cl !important;
  }
}
