@use '../../scss/variables' as v;
@import '../../scss/mixins';
.assignmentsRfpWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
}
.titleContainer {
  height: 12%;
}
.titleWrapper {
  font-size: 22px;
  margin-top: 10px;
  display: flex;
  .titleContent {
    display: flex;
  }
}

.title {
  margin-left: 66px;
  @include mobile {
    margin-left: 16px;
  }
}
.titleTime {
  margin-left: 30px;
  @include mobile {
    margin-left: 6px;
  }
}
.textFollow {
  font-size: 22px;
  margin-top: 20px;
}
.description {
  margin-top: 20px;
}

.contentRight {
  justify-content: end;
}

.contentWrap {
  position: relative;
  height: 100%;
  flex: 1;
  border: 1px solid v.$black-cl;
  margin: 30px 30px;
  overflow: auto;
  &__bottom {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    @include mobile {
      flex-direction: column-reverse;
    }
    .left {
      width: 80%;
      @include mobile {
        width: 100%;
      }
    }
    .right {
      width: 20%;
      @include mobile {
        width: 100%;
      }
    }
  }
  @include mobile {
    margin: 10px;
  }
  .sliderItem {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 10%;
    background-color: v.$gray-light;
  }

  .slider {
    display: flex;
    flex-direction: column;
    height: 100%;
    h3 {
      font-size: 28px;
      text-align: center;
      margin-bottom: 20px;
    }
    ul {
      font-size: 20px;
      li {
        margin-bottom: 10px;
      }
    }
    .sliderImage {
      flex: 1;
      display: flex;
      justify-content: center;
      height: 80%;
      overflow: hidden;
      width: 100%;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
}
