@use '../../scss/variables' as v;
@import '../../scss/mixins';

.bodySection {
  margin: 20px 0 10px;
  font-size: v.$text-bg;
}

.selectUserResult {
  width: 210px;
}

.divider {
  background-color: v.$gray-cl;
  height: 2px;
  margin: 5px 0 10px !important;
}
.progressControlWrapper {
  margin: 20px 0 40px;
  p {
    margin-right: 16px;
  }
}
.titleSelect {
  margin-top: 20px;
  width: 250px;
}
