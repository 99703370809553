@use '../../scss/variables' as v;
@import '../../scss/mixins';

.topAdm {
  padding: 40px 70px;
  height: 100%;
  :global {
    .ant-card-body {
      overflow: hidden;
    }
  }
  @include tablet-custom {
    padding: 24px;
  }
}

.topAdmWarp {
  height: 100%;
  overflow: auto;
}
.topAdmRow {
  margin-top: 30px;
}
.topAdmSubItem {
  margin-top: 50px;
}
.item {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__top {
    width: 263px;
    height: 220px;
    border: 1px solid v.$gray-cl;
    padding: 10px 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    @include tablet-custom {
      width: 240px;
    }
    &:hover {
      border-color: v.$blue-cl;
    }
    img {
      width: 100px;
    }
    p {
      font-size: 22px;
      line-height: 150%;
      @include tablet-custom {
        text-align: center;
      }
    }
  }
  @include tablet-custom {
    margin-bottom: 2rem;
  }
}

.borderItem {
  margin-top: 10px;
  border: 1px solid v.$gray-cl;
  padding: 23px 9px;
  width: 240px;
  height: 78px;
  text-align: center;
  cursor: pointer;
  @include tablet-custom {
    width: 200px;
    height: 98px;
  }
  &:hover {
    border-color: v.$blue-cl;
  }
  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
  }
}
.topAdmRowCustom {
  width: 100%;
  margin-top: 3rem;
}
.subItemCustom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  @include tablet-custom {
    width: 50%;
    margin-bottom: 2rem;
    text-align: center;
  }
}
.subItem {
  border: 1px solid v.$gray-cl;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 310px;
  padding: 22px 32px;
  flex: 1;
  &:hover {
    border-color: v.$blue-cl;
    .borderItem {
      border-color: v.$blue-cl;
    }
  }
  cursor: pointer;
  > p {
    margin-top: 22px;
    flex: 1;
    font-size: 22px;
    font-weight: 400;
    line-height: 150%;
    justify-self: left;
  }
}
.title {
  font-size: 16px;
}

.actionBtn {
  text-align: center;
}
