@use '../../scss/variables' as v;
@import '../../scss/mixins';
.bodySection {
  margin: 20px 0 10px;
  font-size: v.$text-bg;
}
.selectUserResult {
  width: 210px;
}
.tableRusult {
  margin-top: 20px;
  th {
    background-color: v.$blue-cl;
    color: v.$white-cl;
    font-weight: v.$font-weight-normal;
    font-size: v.$text-sm-size;
    line-height: 34px;
  }
  td {
    font-size: v.$text-bg-2;
  }
  th,
  td {
    width: 225px;
    height: 34px;
    border: 1px solid v.$black-cl;
    text-align: center;
  }
}
.bodySectionComment {
  margin: 26px 0 15px;
  font-size: v.$text-bg;
}
.progressControlWrapper {
  margin: 30px 0 60px;
  p {
    margin-right: 16px;
  }
  .titleSelect {
    width: 250px;
  }
}
.progressPlot {
  width: 70%;
  canvas {
    max-width: 100% !important;
    height: 100px !important;
  }
}
.linePlotWrapper {
  margin-top: 20px;
  .linePlot {
    width: 100%;
    canvas {
      max-width: 100% !important;
      height: 306px !important;
    }
  }
}
.descriptionPlotWrapper {
  margin-top: 20px;
  span {
    margin-left: 16px;
  }
  .redColor {
    stroke: #ff0000;
  }
  .blueColor {
    stroke: #132bff;
  }
}
