@use '../../scss/variables' as v;
@import '../../scss/mixins';

.topButton {
  min-width: 124px;
  span {
    font-size: 16px;
    line-height: 100%;
  }
}

.bodySection {
  margin: 20px 0;
  font-size: v.$text-bg;
}
.selectUserResult {
  width: 210px;
}
.tableRusult {
  .rowScore {
    td {
      line-height: 150%;
    }
  }
  th {
    background-color: v.$blue-cl;
    color: v.$white-cl;
    font-weight: v.$font-weight-normal;
    font-size: v.$text-sm-size;
    line-height: 34px;
  }
  td {
    font-size: v.$text-bg-2;
  }
  th,
  td {
    width: 225px;
    height: 34px;
    border: 1px solid v.$black-cl;
    text-align: center;
  }
}
.bodySectionComment {
  margin: 26px 0 15px;
  font-size: v.$text-bg;
}
