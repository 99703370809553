@use '../../scss/variables' as v;
@import '../../scss/mixins';

$td-width: 225px;
.bodySection {
  margin: 20px 0;
  font-size: v.$text-bg;
}

.tableRusult {
  max-width: 100%;
  .rowScore {
    td {
      line-height: 150%;
    }
  }
  th {
    background-color: v.$blue-cl;
    color: v.$white-cl;
    font-weight: v.$font-weight-normal;
    font-size: v.$text-sm-size;
    line-height: 34px;
  }
  td {
    font-size: v.$text-bg-2;
  }
  th,
  td {
    width: $td-width;
    height: 34px;
    border: 1px solid v.$black-cl;
    text-align: center;
  }
}
.bodySectionComment {
  margin: 26px 0 15px;
  font-size: v.$text-bg;
}
.commentWrapper {
  width: 100%;
  min-height: 200px;
  max-width: calc($td-width * 5);
  border: 1px solid v.$black-cl;
  padding: 10px 12px;
  p {
    font-size: 16px !important;
    margin: 8px 0;
  }
}
