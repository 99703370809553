@use '../../scss/variables' as v;

.buttonFooter {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: v.$gray-cl;
  color: v.$text-gray-cl;
}

.buttonWrap {
  display: flex;
  &.disabled {
    button {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  button {
    min-width: 120px;
    height: 100%;
    margin: 0 8px;
  }
  width: 120px;
  margin: 10px;
}

.buttonFinish {
  margin: 10px;
  min-width: 120px;
}
