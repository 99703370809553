@use '../../scss/variables' as v;

.leftMenuWrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  &__content {
    width: 406px;
    height: 100%;
    display: flex;
    background-color: v.$gray-cl;
    flex-direction: column;
  }
}

.top {
  display: flex;
  height: auto;
  align-items: center;
  height: 85px;
  padding: 10px 35px;
}

.bottom {
  padding-top: 100px;
  padding-left: 54px;
  ul li {
    padding: 10px 0;
    cursor: pointer;
    font-size: v.$text-bg;
    font-weight: v.$font-weight-normal;
  }
}

.hamburgerLines {
  display: flex;
  height: 23px;
  width: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
  cursor: pointer;
}

.line {
  display: flex;
  height: 2px;
  width: 100%;
  border-radius: 10px;
  background: v.$hamburger-button-gray;
}
