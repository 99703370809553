@use '../../scss/variables' as v;
@import '../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 70px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
  .stageStep {
    margin-top: 20px;
  }
  .titleTime {
    margin-left: 76px;
  }
  .titleResult {
    margin-top: 10px;
  }
  .title {
    margin-top: 20px;
    font-size: 22px;
  }
  .titleTable {
    margin-left: 30px;
  }
  .divider {
    background-color: v.$gray-cl;
    height: 2px;
    margin: 10px 0;
  }
  .content {
    height: 100%;
    flex: 1;
    overflow: auto;
    margin-top: 35px;
    @include mobile {
      margin: 10px;
    }
  }
  .contentRight {
    justify-content: end;
  }
  .contentRightButton {
    justify-content: end;
    margin-top: 20px;
  }

  .contentWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    .questionCard {
      width: 100%;
      height: 210px;
      margin-top: 12px;
      font-size: 16px;
      span {
        font-size: 16px;
      }
    }
    .questionCardTitle {
      text-align: center;
      font-size: 22px;
    }
  }

  .contentTitle {
    display: flex;
    align-items: center;
    margin-top: 35px;
    padding: 8px 16px 8px 16px;
    border: 1px solid v.$border-cl;
    background: rgba(0, 0, 0, 0.03);
    font-size: larger;
  }

  .form {
    padding: 10px;
  }
}
.tableResult {
  margin-top: 20px;
  th {
    background-color: v.$blue-cl;
    color: v.$white-cl;
    font-weight: v.$font-weight-normal;
    font-size: v.$text-sm-size;
    line-height: 34px;
  }
  td {
    font-size: v.$text-bg-2;
    line-height: 150%;
  }
  th,
  td {
    width: 225px;
    height: 34px;
    border: 1px solid v.$black-cl;
    text-align: center;
  }
}
.tableResultAnswer {
  width: 99%;
  margin-top: 20px;
  font-size: 16px;
  th {
    background-color: v.$blue-cl;
    color: v.$white-cl;
    font-weight: v.$font-weight-normal;
    font-size: v.$text-sm-size;
    line-height: 34px;
  }
  th,
  td {
    width: 225px;
    border: 1px solid v.$black-cl;
    text-align: center;
    line-height: 150% !important;
  }
  .tableRow {
    height: 164px;
    &.wrongAnswer {
      background-color: v.$pink-cl;
    }
    td {
      padding: 8px 12px;
      line-height: 16px;
    }
    .cellJustify {
      text-align: center;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
    .cellStart {
      text-align: left;
    }
    :nth-child(2) {
      width: 5%;
    }
    :nth-child(3) {
      width: 15%;
    }
    :nth-child(4) {
      width: 25%;
    }
    :nth-child(5) {
      width: 15%;
    }
    :nth-child(6) {
      width: 15%;
    }
    :nth-child(7) {
      width: 20%;
    }
  }
}
