@use '../../scss/variables' as v;
@import '../../scss/mixins';

.stepWrap {
  display: flex;
  padding: 0;
  list-style: none;
  overflow: hidden;
  margin-top: 4px;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.step {
  display: flex;
  border: 1px solid #000000;
  border-left-width: 0px;
  height: 50px;
  width: 110px;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  &.active {
    background-color: v.$blue-cl;
    color: v.$white-cl;
  }

  &:first-child {
    border-left-width: 1px;
    p {
      margin-left: 0 !important;
    }
  }
  &:hover {
    background-color: v.$blue-cl;
    color: v.$white-cl !important;
    p {
      color: v.$white-cl !important;
    }
    &:after {
      border-left: 30px solid v.$blue-cl !important;
    }
  }
  p {
    width: 130px;
    color: v.$text-gray-cl;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0;
    outline-style: none;
    font-size: var(--form-font-size);
    font-weight: var(--form-font-weigh);
    margin-left: 40px;
    margin-bottom: 8px;
  }
}

.linkActive {
  background-color: v.$blue-cl;
  p {
    color: v.$white-cl !important;
  }
  &:after {
    border-left: 30px solid v.$blue-cl !important;
  }
}
