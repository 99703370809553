@use '../../scss/variables' as v;
@import '../../scss/mixins';

.groups {
  padding: 40px 70px;
  height: 100%;
  overflow: hidden;
  p {
    font-size: 22px;
  }
  &__wrap {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .groupsTitle {
    p {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  @include tablet-custom {
    padding: 24px;
  }
}

.top {
  height: calc(100% - 100px);
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.navGroupButton {
  margin-left: 5%;
  display: flex;
  align-items: flex-end;
  .linkButton {
    display: flex;
    width: 120px;
    height: 50px;
    background-color: v.$gray-cl;
    align-items: center;
    justify-content: center;
    color: v.$white-cl;
    font-size: 22px;
    border: 1px solid v.$black-cl;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &.activeLink {
      background-color: v.$white-cl;
      color: v.$black-cl;
      height: 54px;
    }
    div {
      display: inline-block;
    }
  }
  .navButton {
    min-width: 120px;
    height: 50px;

    span {
      font-size: 22px;
      line-height: 100%;
    }
  }
  .activeLink {
    span {
      color: green !important;
    }
  }
}

.content {
  height: 100%;
  overflow: hidden;
}

.topGroup {
  margin-bottom: 22px;
  font-size: 16px;
}
.topWrapper {
  .questionnaireWrap {
    margin: 20px 0 30px 20px;
    @include tablet-custom {
      margin: 20px 0 30px 0px;
    }
  }
  .descText {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 24px;
    @include tablet-custom {
      font-size: 16px;
    }
  }
  .groupLinkBtn {
    text-align: right;
    margin-top: 1rem;
    .editModeBtn {
      margin-top: 1rem;
      .topButton {
        &:first-child {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
.topButton {
  min-width: 124px;
  span {
    font-size: 16px;
    line-height: 100%;
  }
}

.body {
  font-size: 16px;
  width: 100%;
  height: calc(100% - 120px);
  border: 1px solid black;
  padding: 10px 20px 50px 20px;
  overflow-y: scroll;
  justify-self: flex-end;
  .bodySection {
    margin: 20px 0;
    font-size: v.$text-bg;
  }
}
