@use '../../scss/variables' as v;

.wrapper {
  max-height: 500px;
  overflow-y: scroll;
}
.mainButton {
  width: 100%;
  height: 100%;
  background-color: v.$orange-cl !important;
  border-color: v.$orange-cl !important;
  border-radius: 4px;
  color: v.$white-cl !important;
  font-size: 16px;
  .buttonTittle {
    display: flex;
    justify-content: center;
    font-size: 16px;
    width: 100%;
    height: 100%;
  }
}
