@use '../../scss/variables' as v;
@import '../../scss/mixins';
.assignmentsRfpWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
}
.titleWrapper {
  font-size: 22px;
  margin-top: 10px;
}
.title {
  margin-left: 66px;
  @include mobile {
    margin-left: 16px;
  }
}
.titleTime {
  margin-left: 30px;
  @include mobile {
    margin-left: 6px;
  }
}
.description {
  margin-top: 52px;
}

.contentRight {
  justify-content: end;
}

.contentWrap {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 30px 30px;
  overflow: auto;
  padding: 16px 20px;
  h2 {
    font-size: 30px;
  }
  @include mobile {
    margin: 10px;
  }
  .sliderItem {
    flex: 1;
    height: 100%;
    width: 100%;
  }

  .slider {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
