@use '../../scss/variables' as v;
@import '../../scss/mixins';

.groups {
  padding: 40px 70px;
  height: 100%;
  &__wrap {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  @include tablet-custom {
    padding: 24px;
  }
}

.top {
  height: calc(100% - 50px);
  flex: 1;
  overflow: auto;
}

.bottom {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  height: 100%;
  overflow: hidden;
}

.action {
  text-align: end;
}
.assignmentsMenuTitle {
  font-size: 22px;
}
.topButtonGroup {
  margin-bottom: 22px;
}
.topButton {
  span {
    font-size: 16px;
    line-height: 100%;
  }
}
.groupButton {
  span {
    font-size: 14px;
  }
}
