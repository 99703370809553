@use '../../scss/variables' as v;
@import '../../scss/mixins';

.stepWrap {
  display: flex;
  border: 0 solid #ccc !important;
  padding: 0;
  border-radius: 5px;
  list-style: none;
  margin-top: 20px;
  width: 100%;
  position: relative;
  .timeWrap {
    position: absolute;
    top: -30%;
    left: 0;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .time {
    display: block;
    width: 18%;
    text-align: left;
  }
}

.step {
  background-color: v.$blue-cl-light;
  display: flex;
  height: 80px;
  width: 18%;
  align-items: center;
  justify-content: center;
  position: relative;
  &:first-child {
    p {
      margin-left: 0 !important;
    }
  }
  &:before,
  &:after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 39px solid transparent;
    border-bottom: 39px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 64%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }
  &:after {
    z-index: 2;
    margin-left: 0px;
    border-left: 30px solid v.$blue-cl-light !important;
  }

  p {
    width: 130px;
    color: v.$text-gray-cl;
    text-decoration: none;
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    border-radius: 0;
    outline-style: none;
    font-size: var(--form-font-size);
    font-weight: var(--form-font-weigh);
    margin-left: 40px;
    margin-bottom: 8px;
  }
}

.linkActive {
  background-color: v.$blue-cl;
  p {
    color: v.$white-cl !important;
  }
  &:after {
    border-left: 30px solid v.$blue-cl !important;
  }
}
