@use '../../scss/variables' as v;
@import '../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  line-height: 24px;
  :global {
    .ant-space-item > button {
      background-color: red;
    }
  }
  &__marginBottom {
    margin-bottom: 30px;
    font-size: 22px;
  }

  .title {
    margin-left: 46px;
  }

  .contentRight {
    justify-content: end;
  }

  .contentRightButton {
    justify-content: end;
    margin-top: 20px;
    .buttonModalPre {
      border-color: v.$hamburger-button-gray !important;
      border-radius: 4px;
      color: v.$hamburger-button-gray !important;
    }
    .buttonLeft {
      border-color: v.$hamburger-button-gray !important;
      border-radius: 4px !important;
      color: v.$hamburger-button-gray !important;
      margin-right: 28px;
    }
  }
}

.description {
  color: v.$text-gray-cl;
  border: 1px solid v.$black-cl;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.titleChart {
  margin-bottom: 30px;
  .widthSelect {
    margin-left: 15px;
    width: 250px;
    @include tablet-custom {
      width: 160px;
    }
  }
}

.chart {
  .marginLeft {
    margin-left: 15px;
  }
}
.noteChart {
  color: v.$text-gray-cl;
  .marginLeft {
    margin-left: 15px;
  }
}
