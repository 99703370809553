.descriptionPlotWrapper {
  margin-top: 20px;
  span {
    margin-left: 16px;
  }
  .redColor {
    stroke: #ff0000;
    margin: 5px 0;
  }
  .blueColor {
    stroke: #132bff;
    margin: 5px 0;
  }
}
.unitWrapper {
  text-align: center;
  .marginLeft {
    margin: 8px 0 8px 0px;
    font-size: 22px;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  50% {
    -webkit-transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 80px;
  border: 4px solid #ff8e8e;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
  opacity: 0.5;
  background: #ff8e8e;
  -webkit-transform: scale(0.8);
  -webkit-animation: pulse 1s ease-in-out infinite;
  animation: pulse 1s ease-in-out infinite;
}
.unanswered {
  color: red;
  margin-right: 10px;
}
.answered {
  color: initial;
}
