@use '../../scss/variables' as v;
@import '../../scss/mixins';

.groups {
  padding: 40px 70px;
  height: 100%;
  &__wrap {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  @include tablet-custom {
    padding: 24px;
  }
}

.top {
  height: calc(100% - 50px);
  flex: 1;
  overflow: auto;
}

.bottom {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  height: 100%;
  overflow: hidden;
}

.action {
  text-align: end;
}
.modalAddUserButton {
  margin-bottom: 20px;
}
.formAddUserItem {
  margin-bottom: 16px;
}
.modalAddUserTitle {
  text-align: center;
  font-size: 16px;
}
.descRegis {
  margin-bottom: 1rem;
}
