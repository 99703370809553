@use '../../scss/variables' as v;

.cancelButton {
  margin-right: 10px;
}
.footer_modal {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.buttonPre {
  border-radius: 4px !important;
  color: v.$hamburger-button-gray !important;
}
.buttonCancel {
  border-radius: 4px !important;
  color: v.$white-cl !important;
  background-color: v.$hamburger-button-gray !important;
}
.buttonPreBg {
  border-radius: 4px !important;
  background-color: v.$hamburger-button-gray !important;
  color: v.$white-cl !important;
}
// Modal Groups
.contentModal {
  overflow: hidden;
  .tableWrapper {
    overflow-y: scroll;
    height: 60vh;
  }
  .paginationWrapper {
    margin-top: 1rem;
    text-align: center;
  }
}

// modal

.buttonUpload {
  padding: 10px 0 0;
  display: flex;
  justify-content: center;
}

.uploadDesc {
  font-size: 14px;
  margin: 20px 0 10px;
  .textDesc {
    line-height: 22px;
  }
}

.iconDownload {
  margin-right: 1rem;
}
