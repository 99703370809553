@use '../../scss/variables' as v;
@import '../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
  margin-bottom: 100px;
  .headWrap {
    margin-bottom: 30px;
    font-size: 22px;
  }
  .stageStep {
    margin-top: 20px;
  }
  .titleWrapper {
    font-size: 22px;
    .title {
      margin-left: 50px;
    }
  }
  .instrText {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .questionTitle {
    font-size: 16px;
  }
  .content {
    height: 100%;
    flex: 1;
    overflow: auto;
    padding-top: 35px;
    @include mobile {
      margin: 10px;
    }
  }
  .contentRight {
    justify-content: end;
  }
  .contentRightButton {
    justify-content: end;
    margin-top: 20px;
  }

  .contentWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    .questionCard {
      width: 100%;
      height: 210px;
      margin-top: 12px;
      font-size: 16px;
      height: fit-content;
      span {
        font-size: 16px;
      }
    }
    .questionCardTitle {
      text-align: center;
      font-size: 22px;
    }
  }

  .contentTitle {
    display: flex;
    align-items: center;
    margin-top: 35px;
    padding: 8px 16px 8px 16px;
    border: 1px solid v.$border-cl;
    background: rgba(0, 0, 0, 0.03);
    font-size: larger;
  }

  .form {
    padding: 10px;
  }
}
