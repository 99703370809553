@use '../../scss/variables' as v;
@import '../../scss/mixins';

.groups {
  padding: 40px 70px;
  height: 100%;
  overflow: hidden;
  p {
    font-size: 22px;
  }
  &__wrap {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .groupsTitle {
    margin-right: 34px;
  }
  @include tablet-custom {
    padding: 40px 20px;
  }
}
.controlGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 26px 0 16px;
  .controlLeft {
    flex: 1;
  }
}

.top {
  height: calc(100% - 100px);
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.navGroupButton {
  margin-left: 5%;
  display: flex;
  align-items: flex-end;
  .linkButton {
    display: flex;
    width: 120px;
    height: 50px;
    background-color: v.$gray-cl;
    align-items: center;
    justify-content: center;
    color: v.$white-cl;
    font-size: 22px;
    border: 1px solid v.$black-cl;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &.activeLink {
      background-color: v.$white-cl;
      color: v.$black-cl;
      height: 54px;
    }
    div {
      display: inline-block;
    }
  }
  .navButton {
    min-width: 120px;
    height: 50px;

    span {
      font-size: 22px;
      line-height: 100%;
    }
  }
  .activeLink {
    span {
      color: green !important;
    }
  }
}

.bottom {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  height: 100%;
  overflow: hidden;
}

.topGroup {
  margin-bottom: 22px;
  font-size: 16px;
}
.topGroupButton {
  margin-right: 20px;
}
.topButton {
  min-width: 124px;
  span {
    font-size: 16px;
    line-height: 100%;
  }
}

.body {
  font-size: 16px;
  width: 100%;
  border: 1px solid black;
  padding: 10px 10% 32px 55px;
  overflow-y: scroll;
  justify-self: flex-end;
  .bodySection {
    margin: 20px 0;
    font-size: v.$text-bg;
  }
  @include tablet-custom {
    padding: 10px 20px 32px 20px;
  }
}
.selectUserResult {
  width: 210px;
}
.tableRusult {
  .rowScore {
    td {
      line-height: 150%;
    }
  }
  th {
    background-color: v.$blue-cl;
    color: v.$white-cl;
    font-weight: v.$font-weight-normal;
    font-size: v.$text-sm-size;
    line-height: 34px;
  }
  td {
    font-size: v.$text-bg-2;
  }
  th,
  td {
    width: 225px;
    height: 34px;
    border: 1px solid v.$black-cl;
    text-align: center;
  }
}
.bodySectionComment {
  margin: 26px 0 15px;
  font-size: v.$text-bg;
}
