@use '../../scss/variables' as v;
.rowWrap {
  height: 100%;
  .cardWrap {
    text-align: center;
    width: 438px;
    .formWarp {
      padding: 0 16px;
    }

    .forgotPw {
      margin-left: 16px;
    }

    .subTitle {
      text-align: left;
      margin-bottom: 24px;
    }
  }
}
