@use '../../scss/variables' as v;

.mainFooter {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: v.$gray-cl;
  color: v.$text-gray-cl;
}
