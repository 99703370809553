@use '../../scss/variables' as v;
@import '../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  line-height: 24px;

  &__marginBottom {
    margin-bottom: 30px;
    font-size: 22px;
  }

  .stageStep {
    &__marginTop {
      margin-top: 20px;
    }
    margin-bottom: 25px;
    width: 100%;
  }

  .title {
    margin-left: 76px;
  }

  .contentRight {
    justify-content: end;
    cursor: pointer;
  }
  .contentRightButton {
    justify-content: end;
    margin-top: 20px;
    span {
      font-size: 16px !important;
    }
  }

  .form {
    padding: 10px;
  }
}
.contentWrap {
  border-top: 1px solid v.$black-cl;
}
.content {
  height: 100%;
  flex: 1;
  @include mobile {
    margin: 10px;
  }
}

.contentTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 10px;
  padding-left: 10px;
  height: 50px;
  font-size: larger;
}

.buttonModalConfirm {
  background-color: v.$orange-cl !important;
  border-color: v.$orange-cl !important;
  border-radius: 4px;
  color: v.$white-cl !important;
}

.buttonModalPre {
  border-radius: 4px;
  color: v.$hamburger-button-gray !important;
}

.buttonCopy {
  border-radius: 4px;
  width: 260px !important;
  color: v.$hamburger-button-gray !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.buttonLeft {
  border-color: v.$hamburger-button-gray !important;
  border-radius: 4px !important;
  color: v.$hamburger-button-gray !important;
}

.maxWidthTable {
  .tableInfo {
    width: 80%;
    &.max {
      width: 100%;
    }
    tbody {
      tr {
        td {
          font-size: 22px;
          line-height: 18px;
        }
      }
    }
    thead {
      th {
        width: 250px !important;
        font-size: 16px !important;
      }
    }
  }
}

.maxWidthTutorial {
  height: fit-content;
  padding: 10px;
  border: 1px solid v.$border-cl;
  max-width: 70% !important;
  border-radius: 10px;
  > div {
    font-size: 22px;
  }
}

.heightBottom {
  height: 100px;
}

.widthSelectTable {
  width: 90%;
  font-size: 16px !important;
}

.contentWrap__table {
  width: 100%;

  th {
    border: 1px solid v.$border-cl;
    text-align: center;
    width: 15%;
    background: #237e3a;
    color: v.$white-cl;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  td {
    border: 1px solid v.$border-cl;
    font-size: 22px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .table__factorial {
    width: 20%;
  }
  .table__unit {
    width: 15%;
  }
}

.firstTable {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px 0;

  .contentTitle {
    margin-top: 0;
  }
}
.fixedNavMonth {
  position: -webkit-sticky;
  position: sticky;
  top: -10px;
  background-color: v.$white-cl;
  z-index: 1;
}
