@use '../../scss/variables' as v;
@import '../../scss/mixins';

.groups {
  padding: 40px 70px;
  height: 100%;
  &__wrap {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  @include tablet-custom {
    padding: 40px 20px;
  }
}

.top {
  height: 100%;
  flex: 1;
  overflow: auto;
}
.body {
  font-size: 16px;
  margin-top: 32px;
  p {
    margin: 26px 0;
  }
  @include tablet-custom {
    :global {
      .ant-table-cell {
        padding: 8px !important;
      }
    }
  }
}
.bottom {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  height: 100%;
  overflow: hidden;
}

.action {
  text-align: end;
}
.assignmentsMenuTitle {
  font-size: 22px;
}
.topButtonGroup {
  margin-bottom: 22px;
}
.topButton {
  span {
    font-size: 16px;
    line-height: 100%;
  }
}
.assignmentTitle {
  font-size: 16px;
}
.assignmentItem {
  margin-bottom: 4px;
  width: 100%;
  > div {
    flex: 1;
    &:first-child {
      max-width: 240px;
    }
    input {
      font-size: 16px;
    }
  }
  input {
    width: 100%;
  }
  input,
  span {
    font-size: 16px;
  }
}

.confirmButton {
  margin-top: 40px;
  font-size: 16px;
  width: 140px;
  height: 48px;
  background: #ed6c01;
  border: none;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}
.controlWrapper {
  padding: 8px;
}
.highlightText {
  background-color: #ffc069;
  padding: 0;
}
.searchInput {
  margin-bottom: 8px !important;
  display: block;
}
