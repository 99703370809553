@use '../../scss/variables' as v;
@import '../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
  &__marginTop {
    margin-top: 20px;
  }
  :global {
    .ant-table-cell {
      vertical-align: top;
    }
    table {
      table-layout: fixed !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #ed6c01 !important;
      border-color: #ed6c01 !important;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: #ed6c01 !important;
      border-color: #ed6c01 !important;
    }
    td.ant-table-cell:nth-child(1),
    td.ant-table-cell:nth-child(2),
    td.ant-table-cell:last-child {
      cursor: initial;
    }
    td.ant-table-cell {
      cursor: text;
      padding: 16px 4px;
      text-align: center;
      @include tablet-custom {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(12) {
          text-align: center;
          padding: 1rem 0;
        }
        padding: 5px;
      }
    }
    th.ant-table-cell {
      padding: 16px 6px;
      &:nth-child(1) {
        width: 3%;
      }
      &:nth-child(2) {
        width: 3%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: 10%;
      }
      &:nth-child(8) {
        width: 10%;
      }
      &:nth-child(9) {
        width: 6%;
      }
      &:nth-child(10) {
        width: 6%;
      }
      &:nth-child(11) {
        width: 19%;
      }
      &:nth-child(12) {
        width: 3%;
      }
      @include tablet-custom {
        padding: 0.75rem 0.5rem;
        &:nth-child(1) {
          width: 4%;
        }
        &:nth-child(2) {
          width: 3%;
        }
        &:nth-child(3) {
          width: 18%;
        }
        &:nth-child(4) {
          width: 8%;
        }
        &:nth-child(5) {
          width: 8%;
        }
        &:nth-child(6) {
          width: 8%;
        }
        &:nth-child(7) {
          width: 8%;
        }
        &:nth-child(8) {
          width: 10%;
        }
        &:nth-child(9) {
          width: 12%;
        }
        &:nth-child(10) {
          width: 12%;
        }
        &:nth-child(11) {
          width: 14%;
        }
        &:nth-child(12) {
          width: 5%;
        }
      }
    }
    td.ant-table-cell[title~='配点'],
    td.ant-table-cell[title~='正解'] {
      cursor: pointer;
    }
    td.ant-table-cell {
      .editable-cell-value-wrap {
        border: 0.5px solid transparent;
        padding: 10px;
        @include tablet-custom {
          padding: 6px;
        }
      }
    }
    td.ant-table-cell {
      &:hover {
        .editable-cell-value-wrap {
          border: 0.5px solid gray;
        }
      }
    }
  }
}

.title {
  margin-left: 76px;
}

.content {
  height: 100%;
  flex: 1;
  overflow: auto;
  @include mobile {
    margin: 10px;
  }
}

.contentWrap {
  height: 100%;
  border: 1px solid v.$border-cl;
}

.contentTitle {
  display: flex;
  align-items: center;
  margin-top: 35px;
  padding-left: 10px;
  border: 1px solid v.$border-cl;
  background: rgba(0, 0, 0, 0.03);
  height: 50px;
  font-size: larger;
}

.form {
  padding: 10px;
  .csvBtn {
    margin-bottom: 20px;
    .csv {
      display: none;
    }
  }
}
.formItem {
  .widthMax {
    width: 100%;
  }
}
.pForm {
  padding-left: 10px;
  font-size: 22px;
}

.heightBottom {
  height: 50px;
}

.buttonAdd {
  border: 0 !important;
  margin-left: 50%;
}

.iconAdd {
  font-size: xx-large;
  color: v.$primary_t1 !important;
}

.iconRemove {
  font-size: large;
  color: v.$blue-cl !important;
}

.select {
  width: 120px;
}
.deleteRowButton {
  cursor: pointer;
}
