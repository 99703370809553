@use '../../scss/variables' as v;
@import '../../scss/mixins';

.taskWarp {
  height: 360px;
  width: 70%;
  @include mobile {
    width: 100%;
  }
  :global {
    .ant-table-thead .ant-table-cell {
      background-color: v.$gray-light !important;
    }
    .ant-table-cell {
      border: 1px solid v.$gray-light-2 !important;
    }
  }
}

.taskTitle {
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
}

.taskTable {
  padding-top: 67px;
  padding-left: 55px;
  @include mobile {
    padding-left: 0px;
  }
  th {
    color: v.$black-cl !important;
  }
}
