@use '../../scss/variables' as v;
@import '../../scss/mixins';

.stepContainer {
  width: 100%;
}
.stepWrap {
  display: flex;
  border: 0 solid #ccc !important;
  padding: 0;
  border-radius: 5px;
  list-style: none;
  overflow: hidden;
  margin-top: 20px;
  width: 100%;
  a {
    width: auto;
  }
}

.step {
  background-color: v.$gray-cl;
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: none;
  @include tablet-custom {
    width: 124px !important;
    height: 105px;
    font-size: 14px;
    padding: 0.5rem;
    padding: 15px 0;
  }
  &:first-child {
    p {
      margin-left: 0 !important;
    }
  }
  &:before,
  &:after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }
  &:after {
    z-index: 2;
    margin-left: 0px;
    border-left: 30px solid v.$gray-cl !important;
  }
  &:hover {
    background-color: v.$blue-cl;
    p {
      color: v.$white-cl !important;
    }
    &:after {
      border-left: 30px solid v.$blue-cl !important;
    }
  }
  p {
    width: 130px;
    color: v.$text-gray-cl;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0;
    outline-style: none;
    font-size: var(--form-font-size);
    font-weight: var(--form-font-weigh);
    margin-left: 40px;
    margin-bottom: 8px;
    @include tablet-custom {
      width: 90px;
    }
  }
}

.linkActive {
  background-color: v.$blue-cl;
  p {
    color: v.$white-cl !important;
  }
  &:after {
    border-left: 30px solid v.$blue-cl !important;
  }
}
