@use '../../scss/variables' as v;
@import '../../scss/mixins';

.title {
  margin-top: 20px;
  font-size: 22px;
}
.titleTable {
  margin-left: 30px;
}
.divider {
  background-color: v.$gray-cl;
  height: 2px;
  margin: 5px 0 10px !important;
}
.tableResultAnswer {
  margin-top: 20px;
  font-size: 16px;
  width: 100%;
  th {
    background-color: v.$blue-cl;
    color: v.$white-cl;
    font-weight: v.$font-weight-normal;
    font-size: v.$text-sm-size;
    line-height: 34px;
  }
  th,
  td {
    width: 225px;
    border: 1px solid v.$black-cl;
    text-align: center;
  }
  .tableRow {
    height: 164px;
    &.wrongAnswer {
      background-color: v.$pink-cl;
    }
    td {
      padding: 8px 12px;
      line-height: 16px;
    }
    .cellJustify {
      text-align: center;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      line-height: 150%;
    }
    .cellStart {
      text-align: left;
      line-height: 150%;
    }
    :nth-child(2) {
      width: 5%;
    }
    :nth-child(3) {
      width: 15%;
    }
    :nth-child(4) {
      width: 25%;
    }
    :nth-child(5) {
      width: 15%;
    }
    :nth-child(6) {
      width: 15%;
    }
    :nth-child(7) {
      width: 20%;
    }
  }
}
