@use '../../scss/variables' as v;
@import '../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
  &__margin {
    margin-top: 20px;
  }
  :global {
    th.ant-table-cell,
    td.ant-table-cell {
      cursor: default;
    }
    td.ant-table-cell {
      cursor: text;
      &:hover {
        .editable-cell-value-wrap {
          border: 0.5px solid gray;
        }
      }
      .editable-cell-value-wrap {
        border: 0.5px solid transparent;
        padding: 5px 10px;
        text-align: start;
        min-height: 70px;
      }
    }
  }
}

.title {
  margin-left: 76px;
  @include mobile {
    margin-left: 16px;
  }
}

.contentRight {
  justify-content: end;
}

.content {
  height: 100%;
  flex: 1;
  overflow: hidden;
  padding-top: 35px;
  @include mobile {
    margin: 10px;
  }
}

.contentWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__top {
    overflow: auto;
    margin-bottom: 20px !important;
    .labelSelect {
      margin-bottom: 5px;
    }
    .selectTemplate {
      width: 100%;
      margin-top: 5px;
    }
  }
  &__bottom {
    overflow: auto;
  }
  &__flex {
    display: flex;
  }
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    height: 55px;
    margin-right: 50px;
  }
  &__table {
    flex: 1 1;
  }
  &__margin {
    margin: 10px;
  }
  &__marginLeft {
    margin-left: 10px;
  }
  .subData {
    display: flex;
  }
}

.contentTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid v.$border-cl;
  background: rgba(0, 0, 0, 0.03);
  height: 50px;
}

.card {
  height: 100%;
  overflow: auto;
}

.heightBottom {
  height: 100px;
}

.previewImage {
  margin-top: 30px;
}

.textAreaItem {
  min-height: 100px;
  height: 100%;
}
