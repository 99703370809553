@use '../../scss/variables' as v;
@import '../../scss/mixins';
.tableResult {
  margin-top: 20px;
  th {
    background-color: v.$blue-cl;
    color: v.$white-cl;
    font-weight: v.$font-weight-normal;
    font-size: v.$text-sm-size;
    line-height: 34px;
  }
  td {
    font-size: v.$text-bg-2;
    line-height: 150%;
  }
  th,
  td {
    width: 225px;
    height: 34px;
    border: 1px solid v.$black-cl;
    text-align: center;
  }
}
